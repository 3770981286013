<template>
	<div class="container">
		<div class="active-div">
			<p @click="handleActiveClick(item.id)" :class="{'active': active == item.id}" v-for="item of activeArr"
				:key="item.id">
				{{item.name}}
			</p>
		</div>
		<div class="box" v-for="item of nowGoods" :key="item.id">
			<div class="left">
				<img :src="item.bannerImage" alt="">
			</div>
			<!-- <div class="center">
				{{item.bannerInfo}}
			</div> -->
			<div class="right">
				<div class="top">
					{{item.bannerName}}
				</div>
				<div class="botton" @click="productClick(item)">
					查看详情》
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { getProgramme } from "@/api/home";
	export default {
		data() {
			return {
				activeArr: [],

				active: '',
				nowGoods: [],
				total: 0,
			}
		},

		mounted() {
			this.activeArr = JSON.parse(localStorage.getItem("navigation"))[1].childes
			// console.log(JSON.parse(localStorage.getItem("businessInfo")))
			if (this.$route.query.id) {
				this.active = this.$route.query.id
				this.getPro(this.active)
			} else {

				this.getPro()
			}
		},
		methods: {
			productClick(item) {

				if (item.jumpUrl) {
					window.open(item.jumpUrl)
				} else {
					this.$router.push({ path: '/thermometer', query: { item: JSON.stringify(item) } }) //产品

				}
			},
			getPro(val) {
				getProgramme({ pageNo: 1, pageSize: 99, jumpType: val }).then(response => {
					let data = response.data
					console.log(data)
					this.nowGoods = data.list
					this.total = data.total
				});
			},

			handleActiveClick(val) {
				if (this.active == val) {
					this.active = ''
				} else {
					this.active = val
				}
				this.getPro(val)
			},
		}

	}
</script>
<style lang="scss" scoped>
	.active-div {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 17px;
	}

	.active-div p {
		width: 174px;
		height: 33px;
		border: 1px solid #979797;
		font-size: 16px;
		font-family: SourceHanSansCN-Normal, SourceHanSansCN;
		font-weight: 400;
		color: #999999;
		line-height: 33px;
		text-align: center;
		cursor: pointer;
	}

	.active-div .active {
		width: 174px;
		height: 33px;
		background: #245ECB;
		border: 1px solid #245ECB;
		color: #FFFFFF;
	}

	.container {
		width: 1020px;
		min-height: 500px;
		margin: 50px auto;
	}

	.box {
		overflow: hidden;
		height: 200px;
		padding: 20px 0;
		margin-top: 50px;
		border-bottom: 2px solid #f2f2f2;

		.right {
			float: right;
			width: 160px;
			position: relative;
			height: 200px;

			.top {
				position: absolute;
				top: 0px;
				right: 0;
				font-weight: 600;
				font-size: 18px;

			}

			.botton {
				position: absolute;
				bottom: 0px;
				right: 0;
				color: #46a6ff;
			}
		}

		.center {

			float: left;
			margin-left: 30px;
			width: 520px;
			font-size: 14px;
			color: #333;
		}

		.left {
			float: left;

			img {
				width: 300px;
				height: 200px;
			}
		}
	}
</style>